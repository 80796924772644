import HeroImg from '../assets/rb.jpg';
import oneImg from '../assets/Interior.jpeg';
import twoImg from '../assets/kt.jpg';
import threeImg from '../assets/12.jpg';
import fourImg from '../assets/11.jpg';
import fiveImg from '../assets/13.jpg';

export default function Services() {
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 px-5 py-20 bg-primary" id='services'>
      {/* Section Title */}
      <div className="col-span-full text-center mb-8">
        <h1 className="text-4xl border-b-4 border-white inline-block font-bold text-white">
          Our Services
        </h1>
      </div>

      {/* Service Cards */}
      {[
        {
          title: 'BUILDING CONSTRUCTION',
          description:
            'We are involved in the construction of Residential, Apartments, Villas, & Commercial Buildings.',
          img: HeroImg,
        },
        {
          title: 'Interiors',
          description:
            'Transform your space into a stylish and functional masterpiece with our interior services. At Ohm Constructions, we offer comprehensive interior design and decoration solutions tailored to your tastes and preferences.',
          img: oneImg,
        },
        {
          title: 'Modern & Kerala Roofings',
          description:
            'We have an expert team in doing all types of roofing, like ceramic tile roofing, stone-coated sheet roofing, shingles roofing & all metal roofing.',
          img: twoImg,
        },
        {
          title: 'Bank Loans',
          description:
            'We have tie-ups with most familiar banks (nationalized & private). All types of loans like personal loans, housing loans, site purchase loans, and business loans are arranged for both government and private employees and self-employed individuals.',
          img: threeImg,
        },
        {
          title: 'Real Estate',
          description:
            'Whether you’re seeking to buy or sell, we are with you every step of the process, ensuring a seamless procedure and transparent communication.',
          img: fourImg,
        },
        {
          title: 'Property Management',
          description:
            'For NRIs owning property in Tirupur District, we offer regular monitoring, maintenance, and customized services. We also assist with buying and selling properties.',
          img: fiveImg,
        },
      ].map((service, index) => (
        <div
          key={index}
          className="max-w-sm w-full mx-auto bg-white border border-gray-200 rounded-lg shadow-lg dark:bg-gray-800 dark:border-gray-700"
        >
          <a href="/">
            <img
              className="rounded-t-lg w-full h-48 object-cover"
              src={service.img}
              alt={service.title}
            />
          </a>
          <div className="p-5">
            <a href="/">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {service.title}
              </h5>
            </a>
            <p className="mb-4 text-gray-700 dark:text-gray-400">{service.description}</p>
            {/* <a
              href="/"
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Learn More
              <svg
                className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </a> */}
          </div>
        </div>
      ))}
    </section>
  );
}
