import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from 'react'

export default function Header() {
    const [toggleMenu, setToggleMenu]  = useState(false);

    return <header className="flex justify-between px-5 py-2 bg-secondary ">
        <a className="font-bold text-white hover:text-orange-400" href="/">M.BALAKUMAR</a>
        <nav className="hidden md:block">
            <ul className="flex text-white hover:text-gray-200 ">
                <li className="flex text-white hover:text-orange-400"><a href="/#Home">Home</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#about">About</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#services">Services</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#Resume">Team</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#contact">Contact</a></li>
            </ul>
        </nav>
        {toggleMenu && <nav className="block md:hidden ">
            <ul onClick={() => setToggleMenu(!toggleMenu)} className="flex flex-col text-white  mobile-nav">
                <li className="flex text-white hover:text-orange-400"><a href="/#Hero">Home</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#about">About</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#services">Services</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#Resume">Team</a></li>
                <li className="flex text-white hover:text-orange-400"><a href="/#contact">Contact</a></li>
            </ul>
        </nav>}
        <button onClick={() => setToggleMenu(!toggleMenu)} className='block md:hidden'><Bars3Icon className='text-white h-5'/></button>
    </header>
}