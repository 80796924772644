import HeroImg from '../assets/Screenshot.png';
import { AiOutlineTwitter, AiOutlineFacebook, AiOutlineInstagram  } from "react-icons/ai";

export default function Hero() {
  const config = {
    subtitle: 'Over 20 years of experience in the construction industry',
    social: {
      twitter: 'https://x.com/OhmConstru10291',
      facebook: 'https://facebook.com/M.Balakumar',
      linkedin: 'https://www.instagram.com/ohmconstructions/',
    },
  };

  return (
    <section className="flex flex-col-reverse md:flex-row bg-primary px-5 py-36 items-center"id='Home'>
      {/* Left Content */}
      <div className="md:w-1/2 flex flex-col text-center md:text-left">
        <h1 className="text-white text-5xl md:text-6xl font-hero-font mb-5">
          Welcome, <br />
          To <span className="text-orange-500 hover:text-white">Ohm Constructions</span>
        </h1>
        <p className="text-white text-2xl">{config.subtitle}</p>
        <div className="flex justify-center md:justify-start py-6 space-x-4">
          <a
            href={config.social.twitter}
            className="text-white hover:text-orange-500 transition-colors"
          >
            <AiOutlineTwitter size={40} />
          </a>
          <a
            href={config.social.facebook}
            className="text-white hover:text-orange-500 transition-colors"
          >
            <AiOutlineFacebook size={40} />
          </a>
          <a
            href={config.social.linkedin}
            className="text-white hover:text-orange-500 transition-colors"
          >
            <AiOutlineInstagram size={40} />
          </a>
        </div>
      </div>

      {/* Right Image */}
      <div className="md:w-1/2 flex justify-center items-center">
        <div className="relative group">
          <img
            className="w-full max-w-sm md:max-w-md rounded-md transform group-hover:scale-105 transition-transform duration-500"
            src={HeroImg}
            alt="Ohm Constructions"
          />
          <h2 className="text-white T text-center mt-4 text-2xl hover:text-white transition-colors">
               ஓம் கன்ஸ்ட்ரக்சன்ஸ்

          </h2>
        </div>
      </div>
    </section>
  );
}
