
export default function Contact() {

    const config = {
        Name: `M.BALAKUMAR`,
        Adds: `18/4, Anusham Nagar,
               100 Feet Road, Udumalpet-642126
               Tirupur Dt., Tamilnadu`,
        Mobile: `99444 92252 / 79045 86884`

    }

    return <section id='contact' className='flex flex-col bg-primary px-5 py-32 text-white'>
        <div className='flex flex-col items-center'>

            <h1 className='text-4xl border-b-4  border-secondary mb-5 w-[140px] font-bold'>Contact</h1>
            <p className='pb-5'>If you want to discuss more in detail, please contact me</p>
            <p className='py-2'><span className='font-bold'></span> {config.Name}</p>
            <p className='py-2'><span className='font-bold'>Address :</span> {config.Adds}</p>
            <p className='py-2'><span className='font-bold'>Phone :</span> {config.Mobile}</p>
        </div>
    </section>
}