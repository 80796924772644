import AboutImg from '../assets/bala.jpeg'; // Updated to use JPG file

export default function About() {
    const config = {
        line1: 'Hi, My name is M.BALAKUMAR. Founder Of OHM CONSTRUCTIONS',
        line2: 'A Place to Find Your Happiness and Shape Your Dreams. We are Providing Excellence with Service, Executing with Solutions.',
        line3: 'Ohm Constructions was started in 2007 with the intention of providing unparalleled quality houses at an affordable price. In addition to quality construction with top-of-the-line materials, trust, relationships, and high degrees of business ethics are the hallmarks of Ohm Constructors.',
    };

    return (
        <section className="flex flex-col md:flex-row bg-secondary px-5 py-10" id="about">
            {/* Image Section */}
            <div className="py-20 md:w-1/4 flex justify-center items-center pl-16 ">
                <img 
                    src={AboutImg} 
                    alt="M. Balakumar - Founder of OHM Constructions" 
                    className="rounded-lg shadow-lg max-w-full h-auto object-cover"
                />
            </div>
            
            {/* Text Section */}
            <div className="md:w-3/4 flex justify-center items-center">
                <div className="flex flex-col justify-center text-white max-w-lg px-22">
                    <h1 className="text-4xl border-b-4 border-primary mb-5 w-fit font-bold">About Me</h1>
                    <p className="pb-5">{config.line1}</p>
                    <p className="pb-5">{config.line2}</p>
                    <p className="pb-5">{config.line3}</p>
                </div>
            </div>
        </section>
    );
}
