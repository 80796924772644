import AboutImg from '../assets/team.jpg';

export default function Resume() {
  const config = {
    lines: [
      '• To enhance the quality of constructions',
      '• To serve with the best value for money and high return on project',
      '• To acquaint clients with residential and commercial projects to standards',
      '• Serve with an environment of professionalism, teamwork, and excellence',
      '• To respect all environmental rules, regulations, and legal requirements',
      '• To enhance customer value.',
    ],
  };

  return (
    <section className="flex flex-col md:flex-row bg-secondary px-5 py-32" id="Resume">
      {/* Image Section */}
      <div className="md:w-1/2 flex justify-center items-center">
        <img
          src={AboutImg}
          alt="Team"
          className="rounded-lg shadow-lg w-full max-w-sm md:max-w-md"
        />
      </div>

      {/* Text Section */}
      <div className="md:w-1/2 flex justify-center items-center mt-8 md:mt-0">
        <div className="flex flex-col justify-center text-white max-w-lg">
          <h1 className="text-4xl border-b-4 border-primary mb-5 font-bold">
            The Strength of the Team
          </h1>
          {config.lines.map((line, index) => (
            <p className="pb-3" key={index}>
              {line}
            </p>
          ))}
        </div>
      </div>
    </section>
  );
}
