import './App.css';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Resume from './components/Resume';

function App() {
  return (
    <div className="App">
      <div className="sticky top-0 z-10">
        <Header />
      </div>
      <Hero />
      <About />
      <Services />
      {/* <Projects /> */}
      <Resume />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
